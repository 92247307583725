import React from 'react';
import Layout from '../components/layout';
import CurrentUser from '../components/login';

const LoggedInScreen = () => {
  return (
    <Layout>
      {/* <CurrentUser/> */}
    </Layout>
  );
};

export default LoggedInScreen
;
